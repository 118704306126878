import { toast } from '@/utils/dialog';
import Autocomplete from '@/components/Autocomplete.vue';
import DatePicker from '@/components/DatePicker.vue';
import Field from '@/components/Field.vue';
import MySwitch from '@/components/iSwitch.vue';
import QRDownloader from '@/components/QRDownloader.vue';
import saveButton from '../saveButton.vue';
import TimePicker from '@/components/TimePicker.vue';

export default {
  components: {
    Autocomplete,
    DatePicker,
    Field,
    MySwitch,
    QRDownloader,
    saveButton,
    TimePicker
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  created() {
    this.noEndDate = this.oRun.to_date == '3000-12-31' ? true : false;
    this.toDate = this.noEndDate ? null : this.oRun.to_date;
    this.vehicle = this.oRun.vehicle_name;
    this.driver = this.oRun.driver_name;
    this.cdp = this.oRun.cdp_name;
  },
  data() {
    return {
      loading: false,
      toDate: null,
      noEndDate: false,
      cdp: null,
      driver: null,
      vehicle: null,
      oRun: { from_time: null, ...this.run },
      days: this.run.days ? JSON.parse(this.run.days) : []
    };
  },
  computed: {
    runId() {
      return this.paramsRunId || this.run.id;
    },
    breadcumbs() {
      return ['menu.dat', this.run.id ? 'run.edit' : 'run.new', 'global.general'];
    },
    paramsRunId() {
      return this.$route.params.runId;
    },
    weekDays() {
      return [
        { name: this.$t('global.weekDays.sunday'), value: 6 },
        { name: this.$t('global.weekDays.monday'), value: 0 },
        { name: this.$t('global.weekDays.tuesday'), value: 1 },
        { name: this.$t('global.weekDays.wednesday'), value: 2 },
        { name: this.$t('global.weekDays.thursday'), value: 3 },
        { name: this.$t('global.weekDays.friday'), value: 4 },
        { name: this.$t('global.weekDays.saturday'), value: 5 }
      ];
    },
    minDate() {
      let d = new Date();
      return new Date(d.setDate(d.getDate() - 7));
    },
    maxDate() {
      return this.$moment().add(1, 'years').toDate();
    },
    hours() {
      let startTime = this.$moment(this.oRun.from_time, 'HH:mm:ss');
      let endTime = this.$moment(this.oRun.to_time, 'HH:mm:ss');
      let workingHours = endTime.diff(startTime, 'hours');
      workingHours = Math.sign(workingHours) == -1 ? 24 + workingHours : workingHours;
      return workingHours;
    }
  },
  watch: {
    run(run) {
      this.oRun = run;
      this.noEndDate = run.to_date == '3000-12-31' ? true : false;
      this.toDate = this.noEndDate ? null : run.to_date;
      this.vehicle = run.vehicle_name;
      this.driver = run.driver_name;
      this.cdp = run.cdp_name;
      this.days = run.days ? JSON.parse(run.days) : [];
    },
    '$route.path'(value) {
      if (value === '/data/runs/add')
        this.$store.dispatch('setBreadcumbs', ['menu.dat', 'run.new', 'global.general']);
    }
  },
  methods: {
    setDays(weekDay) {
      this.days.push(weekDay);
    },
    deleteDays(weekDay) {
      const index = this.days.indexOf(weekDay);
      this.days.splice(index, 1);
    },
    prepareData() {
      const { oRun: R } = this;
      const data = {
        name: R.name,
        description: R.description,
        employee_id: R.employee_id,
        vehicle_id: R.vehicle_id,
        days: JSON.stringify(this.days),
        suspend_from_date: R.suspend_from_date,
        suspend_to_date: R.suspend_to_date,
        close_door_pharmacy_id: R.close_door_pharmacy_id
      };
      if (this.runId) {
        return data;
      } else {
        return {
          from_date: R.from_date,
          to_date: this.noEndDate ? '3000-12-31' : this.toDate,
          from_time: R.from_time,
          to_time: R.to_time,
          ...data
        };
      }
    },

    async onSubmit() {
      if (this.validate()) {
        this.loading = true;
        try {
          const { data } = await this.saveRun();
          this.oRun = { ...this.oRun, ...data };
          this.oRun.vehicle_name = this.vehicle;
          this.oRun.driver_name = this.driver;
          this.oRun.cdp_name = this.cdp;
          toast('success', this.$t('messages.saved'), 5000);
          this.$emit('change', this.oRun);
          this.$router.replace({
            path: `/data/runs/${this.oRun.id}`
          });
        } catch (error) {
          console.error(error);
        }
        this.loading = false;
      }
    },
    async saveRun() {
      const { id } = this.run;
      const pData = this.prepareData();
      const method = id ? 'put' : 'post';
      const url = `/driver_runs${id ? `/${id}` : ''}`;
      return await this.Api[method](url, pData);
    },
    validate() {
      let htmlValidator = false;
      if (this.$refs.form) {
        htmlValidator = this.$refs.form.checkValidity();
        if (!htmlValidator) this.$refs.form.reportValidity();
      }
      if (htmlValidator) htmlValidator = this.validateDays();
      return htmlValidator;
    },
    validateDays() {
      const result = this.days.length;
      if (result === 0) toast('error', this.$t('runs.validations.sCalendar'), 5000);

      return result;
    },
    timeParser(time) {
      return time.toLocaleTimeString();
    }
  },
  props: {
    run: {
      type: Object,
      default: () => {}
    }
  }
};
